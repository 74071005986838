import { useMemo, useEffect } from "react";
import { TileLayer, Marker, Tooltip, useMap } from "react-leaflet";
import {
  LatLngBounds,
  latLngBounds,
  latLng,
  icon,
  LeafletMouseEvent,
} from "leaflet";
import { GetSitesResponse, SiteType } from "../../features/sites/sitesAPI";
import { useAppDispatch } from "../../app/hooks";
import { focusSite } from "../../features/view/viewSlice";
import { useSnackbar } from "notistack";
import newMarker from "../../assets/mapMarkerOK.svg";
import newMarkerAlarm from "../../assets/mapMarkerAlarm.svg";
import newMarkerOffline from "../../assets/mapMarkerOffline.svg";
import noLeo from "../../assets/leoNotInstalled.png";
import useTansitionNavigate from "../../hooks/useTransitionNavigate";
import { useLocation } from "react-router-dom";

type Props = {
  sites: GetSitesResponse;
};

const redIcon = icon({
  iconUrl: newMarkerAlarm,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  tooltipAnchor: [12, -16],
});

const greenIcon = icon({
  iconUrl: newMarker,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  tooltipAnchor: [12, -16],
});

const offlineIcon = icon({
  iconUrl: newMarkerOffline,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  tooltipAnchor: [12, -16],
});

// Saving for another Site Status
// const yellowIcon = icon({
//     iconUrl: yellowDot,
//     iconSize: [32, 32],
//     iconAnchor: [16, 32],
//     popupAnchor: [0, -32],
//     tooltipAnchor: [12, -16],
// });

const noLeoIcon = icon({
  iconUrl: noLeo,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  tooltipAnchor: [12, -16],
});

const LeafletMap = ({ sites }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useTansitionNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const map = useMap();

  const bounds: LatLngBounds | undefined = useMemo(() => {
    let b: LatLngBounds | undefined = undefined;
    sites
      ?.filter((s) => s.latitude !== null && s.longitude !== null)
      ?.forEach((site) => {
        if (b === undefined) {
          b = latLngBounds([
            latLng(site.latitude as number, site.longitude as number),
          ]);
        } else {
          b.extend([site.latitude as number, site.longitude as number]);
        }
      });

    return b;
  }, [sites]);

  function handleMarkerClick(
    e: LeafletMouseEvent,
    siteId: number,
    leoInstalled: boolean,
    siteNameAndLocation: string,
    site: SiteType,
  ) {
    if (leoInstalled) {
      dispatch(focusSite(siteId));
      const url = `/site/${siteId}/${encodeURIComponent(siteNameAndLocation)}`;

      const mapRect = map.getContainer().getBoundingClientRect();

      // to get the map shrinking animation I want to share size data about the current map to give the illusion it shrunk down

      // it would also be awesome to grab the current bounds and animate zooming in on the single site
      if (location.pathname !== url) {
        navigate(url, {
          state: { site, dashboardMapRect: mapRect },
        });
      }
    } else {
      enqueueSnackbar({
        variant: "warning",
        message: "This site doesn't have a Leo installed yet!",
      });
    }
  }

  useEffect(() => {
    if (bounds && map) {
      // I really don't understand what the padding is actually applying here but it works
      map.fitBounds(bounds, { padding: [15, 15] });
    }
  }, [map, bounds]);

  useEffect(() => {
    if (!map.getContainer()) return;
    const resizeObserver = new ResizeObserver((_e, _o) => {
      map.invalidateSize();
    });
    resizeObserver.observe(map.getContainer());

    return () => resizeObserver.disconnect();
  }, [map, bounds]);

  return (
    <>
      {" "}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {sites
        ?.filter(
          (s) => s.latitude !== null && s.longitude !== null && s.ingest_data,
        )
        ?.map((site, index) => {
          const position = latLng([
            site.latitude as number,
            site.longitude as number,
          ]);
          let icon = greenIcon;

          // check if in alarm and make redIcon
          if (site.active_alarm_count >= 1) {
            icon = redIcon;
          }

          if (site.offline) {
            icon = offlineIcon;
          }

          if (!site.leo_installed) {
            icon = noLeoIcon;
          }

          // other if to check if yellow
          // save yellow icon for other site status

          return (
            <Marker
              eventHandlers={{
                click: (e) =>
                  handleMarkerClick(
                    e,
                    site.store_num,
                    site.leo_installed,
                    `${site.store_name} - ${site.store_city}, ${site.store_state}`,
                    site,
                  ),
              }}
              icon={icon}
              key={`${site.store_name}${index}`}
              data-key={site.store_name}
              position={position}
            >
              <Tooltip direction="right">{site.store_name}</Tooltip>
            </Marker>
          );
        })}
    </>
  );
};

export default LeafletMap;
