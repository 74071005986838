import {
  memo,
  useMemo,
  useRef,
  useState,
  useDeferredValue,
  useEffect,
} from "react";
import { GroupedVirtuoso, GroupedVirtuosoHandle } from "react-virtuoso";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import {
  selectSiteList,
  selectSiteOperators,
} from "../../../../features/auth/authSlice";
import { selectSiteToFocus } from "../../../../features/view/viewSlice";
import {
  SiteType,
  SitesGroupedByOperator,
  useGetSitesQuery,
} from "../../../../features/sites/sitesAPI";
import Loading from "../../../Utility/Loading";
import SiteListHeader from "./SiteListHeader/SiteListHeader";
import Site from "./Site/Site";
import { useDebounce } from "../../../../hooks/useDebounce";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  selectFilteredSiteOperators,
  toggleFilterOperatorThunk,
  setOperatorsFilterThunk,
  resetOperatorsFilterThunk,
  selectSiteFilterValue,
} from "../../../../features/sites/sitesSlice";
import { selectFilteredSitesSelector } from "../../../../features/sites/sitesAPI";
import { selectFilteredSiteList } from "../../../../features/auth/authSlice";

interface Props {
  // entered: boolean;
  handleStoresDrawerToggle: () => void;
  isMobileScreen: boolean;
}

interface EmptyListProps {
  isScrolling: boolean;
}

export const EmptyList = memo(({ isScrolling }: EmptyListProps) => {
  return (
    <Site
      site={{
        active_alarm_count: 0,
        critical_active_alarm_count: 0,
        cloud_start_date: null,
        store_name: "No Sites Found",
        store_num: 0,
        store_city: "",
        store_state: "",
        latitude: 0,
        longitude: 0,
        offline: true,
        // mac_address: null,
        cloud_users_limit: 10,
        cloud_end_date: null,
        floor_plan_enabled: false,
        leo_installed: false,
        leo_version: "",
        store_address: "",
        store_operator: "HL",
        terminal_mode_enabled: false,
        controllers: [],
        cloud_contacts: [],
        time_zone: null,
        controllers_in_alarm: null,
        chain_id: 0,
        ingest_data: false,
        mac_address: null,
        store_order: 0,
        mqtt_path: "",
        voice_sms_subscription: null,
        sms_receivers_limit: 0,
        voip_receivers_limit: 0,
      }}
      index={0}
      isScrolling={isScrolling}
      isVisible
      isMobileScreen={false}
    />
  );
});

export type SiteFilterState = {
  filterBy: keyof SiteType;
  filterString: string;
};

const SiteList = memo(({ handleStoresDrawerToggle, isMobileScreen }: Props) => {
  const dispatch = useAppDispatch();
  const filteredSiteList = useAppSelector(selectFilteredSiteList);
  const store_list = useAppSelector(selectSiteList);
  const siteToFocus = useAppSelector(selectSiteToFocus);

  const [showSiteOperatorFilter, setShowSiteOperatorFilter] = useState(false);

  const filteredSiteOperators = useAppSelector(selectFilteredSiteOperators);

  const handleFilterSiteOperator = (chain: string) => {
    dispatch(toggleFilterOperatorThunk(chain));
  };
  const handleFilterAllSiteOperators = (chains: string[]) => {
    dispatch(setOperatorsFilterThunk(chains));
  };
  const handleUnfilterAllSiteOperators = () => {
    dispatch(resetOperatorsFilterThunk());
  };

  const filteredSiteOperatorsDeferred = useDeferredValue(filteredSiteOperators);

  const [isScrolling, setIsScrolling] = useState(false);

  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("ASC");

  const siteFilterValue = useAppSelector(selectSiteFilterValue);
  const filterString = useDebounce(siteFilterValue);

  const handleSetSortASC = () => setSortDirection("ASC");
  const handleSetSortDESC = () => setSortDirection("DESC");

  // So We cant actaully filter the sites here because
  // we get our list of siteOperators here
  // maybe we can create a selector for this!?!
  const { data, isLoading } = useGetSitesQuery(
    { store_list },
    {
      refetchOnMountOrArgChange: 300,
      pollingInterval: 600000,
      selectFromResult: (result) => ({
        ...result,
        data: selectFilteredSitesSelector(result, filteredSiteList),
      }),
    },
  );

  const leoInstalledSites = useMemo(() => {
    if (data) {
      return data.filter((site) => site.leo_installed);
    }
    return [];
  }, [data]);

  const siteOperators = useAppSelector(selectSiteOperators, {
    devModeChecks: { stabilityCheck: "never" },
  });

  const groupedSites: SitesGroupedByOperator = useMemo(() => {
    if (leoInstalledSites?.length) {
      const returnData: SitesGroupedByOperator = [];
      for (const site of leoInstalledSites) {
        let group = site.store_operator;
        if (group === "") group = "No Group";

        const hidden = filteredSiteOperatorsDeferred.includes(group);
        const exists = returnData.find((d) => d.group === group);

        if (!hidden) {
          if (exists) {
            exists.sites.push(site);
          } else {
            returnData.push({
              group,
              sites: [site],
            });
          }
        }
      }
      return returnData.sort((a, b) =>
        a.group
          .toLowerCase()
          .localeCompare(b.group.toLowerCase(), undefined, { numeric: true }),
      );
    }
    return [];
  }, [leoInstalledSites, filteredSiteOperatorsDeferred]);

  // no longer filtered here. it is sjust sorted
  const filteredGroupedSites: SitesGroupedByOperator = useMemo(() => {
    if (groupedSites) {
      const dataClone = structuredClone(groupedSites);
      for (const group of dataClone) {
        group.sites = group.sites.sort((a, b) =>
          a.store_name.localeCompare(b.store_name, "en-US", {
            numeric: true,
          }),
        );

        if (sortDirection === "DESC") {
          group.sites.reverse();
        }
      }
      const filteredEmptyGroups = dataClone.filter((v) => v.sites.length > 0);
      if (sortDirection === "DESC") {
        return filteredEmptyGroups.reverse();
      }
      return filteredEmptyGroups;
    }
    return [];
  }, [groupedSites, sortDirection]);

  const filteredGroupCounts = useMemo(() => {
    const countsArr: number[] = [];
    for (const group of filteredGroupedSites) {
      countsArr.push(group.sites.length);
    }
    return countsArr;
  }, [filteredGroupedSites]);

  const groupedSitesInOrder = useMemo(() => {
    const sitesArr: SiteType[] = [];
    for (const group of filteredGroupedSites) {
      sitesArr.push(...group.sites);
    }
    return sitesArr;
  }, [filteredGroupedSites]);

  useEffect(() => {
    if (siteToFocus !== null && virtRef.current) {
      const siteIndex = groupedSitesInOrder.findIndex(
        (v) => v.store_num === siteToFocus,
      );
      if (siteIndex > -1) {
        virtRef.current.scrollToIndex(siteIndex);
      }
    }
  }, [siteToFocus, groupedSitesInOrder]);

  const virtRef = useRef<GroupedVirtuosoHandle>(null);

  return (
    <>
      <SiteListHeader
        filterStringDebounced={filterString}
        handleSetSortASC={handleSetSortASC}
        handleSetSortDESC={handleSetSortDESC}
        handleStoresDrawerToggle={handleStoresDrawerToggle}
        sortDirection={sortDirection}
        showSiteOperatorFilter={showSiteOperatorFilter}
        setShowSiteOperatorFilter={setShowSiteOperatorFilter}
        operators={siteOperators}
        filteredSiteOperators={filteredSiteOperators}
        handleFilterSiteOperator={handleFilterSiteOperator}
        handleFilterAllSiteOperators={handleFilterAllSiteOperators}
        handleUnfilterAllSiteOperators={handleUnfilterAllSiteOperators}
      />

      {isLoading ? (
        <Loading size={"2rem"} sx={{ marginY: "0.5rem" }} />
      ) : (
        <GroupedVirtuoso
          ref={virtRef}
          height="100%"
          style={{ position: "relative" }}
          isScrolling={setIsScrolling}
          groupCounts={filteredGroupCounts}
          increaseViewportBy={100}
          overscan={10}
          groupContent={(i) => (
            <ListItem
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              <ListItemText primary={filteredGroupedSites[i].group} />
            </ListItem>
          )}
          itemContent={(i, groupI) => {
            const site = groupedSitesInOrder[i];
            const group = filteredGroupedSites[groupI].group;
            return (
              <Site
                site={site}
                index={i}
                isScrolling={isScrolling}
                isVisible={true}
                key={`${site.store_num}${group}`}
                isMobileScreen={isMobileScreen}
              />
            );
          }}
          defaultItemHeight={72}
          components={{
            EmptyPlaceholder: () => <EmptyList isScrolling={isScrolling} />,
          }}
        />
      )}
    </>
  );
});

export default SiteList;
