export const ControllerTypes = [
  "E2",
  "E3",
  "SiteSupervisor",
  "AKSC255",
  "SM880",
  "SM800A",
  "EV3224",
  "KiloNode",
  "EVCO RTU",
  "EVCO RTU V2",
  "EVCO RTU Zones",
  "EVCO RTU Zones V2",
  "EVCO Lighting",
  "EVCO Lighting V2",
  "EVCO Monitor",
  "EVCO Power Monitor",
  "EVCO HGM-MZ",
  "EVCO HGM-SZ",
  "EVCO Condensing Unit",
] as const;

export enum ControllerType {
  E2 = "E2",
  E3 = "E3",
  SiteSupervisor = "SiteSupervisor",
  AKSC255 = "AKSC255",
  SM880 = "SM880",
  SM800A = "SM800A",
  EV3224 = "EV3224",
  KiloNode = "KiloNode",
  EvcoRtu = "EVCO RTU",
  EvcoRtuV2 = "EVCO RTU V2",
  EvcoRtuZones = "EVCO RTU Zones",
  EvcoRtuZonesV2 = "EVCO RTU Zones V2",
  EvcoLighting = "EVCO Lighting",
  EvcoLightingV2 = "EVCO Lighting V2",
  EvcoMonitor = "EVCO Monitor",
  EvcoPowerMonitor = "EVCO Power Monitor",
  EvcoHgmMz = "EVCO HGM-MZ",
  EvcoHgmSz = "EVCO HGM-SZ",
  EvcoCondensingUnit = "EVCO Condensing Unit",
}

export const CustomEvcoControllers = [
  ControllerType.EV3224,
  ControllerType.KiloNode,
  ControllerType.EvcoRtu,
  ControllerType.EvcoRtuV2,
  ControllerType.EvcoRtuZones,
  ControllerType.EvcoRtuZonesV2,
  ControllerType.EvcoLighting,
  ControllerType.EvcoLightingV2,
  ControllerType.EvcoMonitor,
  ControllerType.EvcoPowerMonitor,
  ControllerType.EvcoHgmMz,
  ControllerType.EvcoHgmSz,
  ControllerType.EvcoCondensingUnit,
];

export const TerminalModeControllers = [
  ControllerType.E2,
  ControllerType.E3,
  ControllerType.SiteSupervisor,
  ControllerType.KiloNode,
  ControllerType.EvcoRtu,
  ControllerType.EvcoRtuV2,
  ControllerType.EvcoRtuZones,
  ControllerType.EvcoRtuZonesV2,
  ControllerType.EvcoLighting,
  ControllerType.EvcoLightingV2,
  ControllerType.EvcoMonitor,
  ControllerType.EvcoPowerMonitor,
  ControllerType.EvcoHgmMz,
  ControllerType.EvcoHgmSz,
  ControllerType.EvcoCondensingUnit,
];

export const isCustomEvcoController = (controllerType: ControllerType) => {
  return CustomEvcoControllers.includes(controllerType);
};

export const validPasswordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
