export default function useCheckDomainAndRedirect() {
  const origin = window.location.origin;
  if (
    import.meta.env.PROD &&
    (origin === "https://leocloud-react.azurewebsites.net" ||
      origin === "https://leocloud.us")
  ) {
    // handle switching to www.leocloud.us
    window.location.replace("https://www.leocloud.us");
  }
}
