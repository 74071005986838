import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { setDetectDelay } from "devtools-detector";
import { SnackbarProvider } from "notistack";
import { PropsWithChildren, Suspense, useMemo } from "react";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  createMemoryRouter,
} from "react-router-dom";
import {
  useAppSelector,
  useCheckColorPreference,
  useCheckDeviceSize,
} from "./app/hooks";
import AppLayout from "./components/Layout/AppLayout";
import AuthLayout from "./components/Layout/AuthLayout";
import ToggleColorPreference from "./components/Layout/Header/ToggleColorPreference";
import SessionExpiredDialog from "./components/Modals/SessionExpiredDialog";
import PrivateRoute from "./components/ReactRouter/PrivateRoute";
import ErrorSnackbar from "./components/Utility/CustomAlerts/ErrorSnackbar";
import InfoSnackbar from "./components/Utility/CustomAlerts/InfoSnackbar";
import SuccessSnackbar from "./components/Utility/CustomAlerts/SuccessSnackbar";
import WarningSnackbar from "./components/Utility/CustomAlerts/WarningSnackbar";
import Loading from "./components/Utility/Loading";
import RouterErrorBoundary from "./components/Utility/RouterErrorBoundary";
import { selectIsAuthenticated } from "./features/auth/authSlice";
import { useGetSitesPermissionsQuery } from "./features/sites/sitesAPI";
import {
  selectCustomThemePalette,
  selectTheme,
} from "./features/view/viewSlice";
import useCheckDomainAndRedirect from "./hooks/appHooks/useCheckDomainAndRedirect";
import DoesNotExist from "./pages/404/DoesNotExist";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import ForgotPasswordReset from "./pages/Auth/ForgotPassword/ForgotPasswordReset";
import Login from "./pages/Auth/Login/Login";
import TwoFactorCode from "./pages/Auth/Login/TwoFactorCode";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import ResetUsername from "./pages/Auth/ResetUsername/ResetUsername";
import Help from "./pages/Help/Help";
import SitePageWrapper from "./pages/Site/SitePage/SitePageWrapper";
import lazyWithRetry from "./utils/lazyWithRetry";

const Dashboard = lazyWithRetry(() => import("./pages/Dashboard/Dashboard"));

const AdminPortalWrapper = lazyWithRetry(
  () => import("./pages/AdminPortal/AdminPortalWrapper"),
);

const UserAccountWrapper = lazyWithRetry(
  () => import("./pages/UserAccount/UserAccountWrapper"),
);

const AllSiteAlarmsWrapper = lazyWithRetry(
  () => import("./pages/AllSiteAlarms/AllSiteAlarmsWrapper"),
);

const AnalyticsWrapper = lazyWithRetry(
  () => import("./pages/Analytics/AnalyticsWrapper"),
);

const OfflineSitesWrapper = lazyWithRetry(
  () => import("./pages/OfflineSites/OfflineSitesWrapper"),
);

const SiteListWrapper = lazyWithRetry(
  () => import("./pages/SiteList/SiteListWrapper"),
);

const SitesWithActiveAlarmsWrapper = lazyWithRetry(
  () => import("./pages/SitesWithActiveAlarms/SitesWithActiveAlarmsWrapper"),
);

const ControllersViewWrapper = lazyWithRetry(
  () => import("./pages/Site/Controllers/ControllersViewWrapper"),
);

const ApplicationsGraphWrapper = lazyWithRetry(
  () =>
    import("./pages/Site/Graphs/ApplicationGraphs/ApplicationsGraphWrapper"),
);

const SiteAnalysisPageWrapper = lazyWithRetry(
  () => import("./pages/Site/SiteAnalysis/SiteAnalysisPageWrapper"),
);

const SiteAlarmsWrapper = lazyWithRetry(
  () => import("./pages/Site/SiteAlarms/SiteAlarmsWrapper"),
);

const SiteConfigPageWrapper = lazyWithRetry(
  () => import("./pages/Site/SiteSettings/SiteSettingsPageWrapper"),
);

const FloorplansWrapper = lazyWithRetry(
  () => import("./pages/Site/Floorplans/FloorplansWrapper"),
);

const RemoteAccessWrapper = lazyWithRetry(
  () => import("./pages/Site/RemoteAccess/RemoteAccessWrapper"),
);

const FullScreenRemoteAccessWrapper = lazyWithRetry(
  () => import("./pages/Site/RemoteAccess/FullScreenRemoteAccessWrapper"),
);

const CustomGraphsWrapper = lazyWithRetry(
  () => import("./pages/Site/Graphs/CustomGraphs/CustomGraphsWrapper"),
);

const LeoAlertLogsWrapper = lazyWithRetry(
  () => import("./pages/LeoAlertRecords/LeoAlertRecordsWrapper"),
);

const SuspenseWithFallback = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

declare module "notistack" {
  interface VariantOverrides {
    success: {
      title?: string;
      moreInfo?: string;
    };
    error: {
      title?: string;
      moreInfo?: string;
    };
    info: {
      title?: string;
      moreInfo?: string;
    };
    warning: {
      title?: string;
      moreInfo?: string;
    };
  }
}

setDetectDelay(1000);

function App() {
  const colorMode = useAppSelector(selectTheme);
  const themePallete = useAppSelector(selectCustomThemePalette);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useCheckDomainAndRedirect();
  useCheckColorPreference();
  useCheckDeviceSize();

  const { data: _data, isLoading } = useGetSitesPermissionsQuery();

  // A custom theme for this app
  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme({
          palette: {
            mode: colorMode,
            contrastThreshold: 3,
            primary: {
              main: themePallete.primary.main, // '#556cd6',
            },
            secondary: {
              main: themePallete.secondary.main, //'#19857b',
            },
            error: {
              main: themePallete.error.main,
            },
            success: {
              main: themePallete.success.main, // #40bf6d <- This is a great looking green text color but not so good on the sidebar
            },
            warning: {
              main: themePallete.warning.main,
            },
            info: {
              main: themePallete.info.main,
            },
            background: {
              default:
                colorMode == "light"
                  ? themePallete.background.default.light
                  : themePallete.background.default.dark, // #fafafa : #303030
              paper:
                colorMode == "light"
                  ? themePallete.background.paper.light
                  : themePallete.background.paper.dark, // "#fff" : "#424242"
            },
          },
          components: {
            MuiUseMediaQuery: {
              defaultProps: {
                noSsr: true,
              },
            },
            MuiDialog: {
              styleOverrides: {
                paper: ({ theme }) =>
                  theme.unstable_sx({
                    margin: { xs: 1, sm: 2, md: 3 },
                  }),
              },
            },
            MuiButton: {
              defaultProps: {
                variant: "contained",
              },
            },
            // MuiDialogContent: {
            //   styleOverrides: {
            //     root: ({ theme }) =>
            //       theme.unstable_sx({
            //         paddingX: { xs: 1, sm: 2, md: 2.5 },
            //         paddingY: { xs: 1, sm: 2 },
            //       }),
            //   },
            // },
          },
        }),
        {},
      ),
    [colorMode, themePallete],
  );

  const routes = useMemo<RouteObject[]>(() => {
    return [
      {
        element: <AuthLayout />,
        errorElement: <RouterErrorBoundary />,
        children: [
          { path: "login", Component: Login },
          { path: "twoFactorAuthCode", Component: TwoFactorCode },
          { path: "two-factor-auth", Component: TwoFactorCode },
          { path: "resetPassword", Component: ResetPassword },
          { path: "resetUsername", Component: ResetUsername },
          { path: "forgotPassword", Component: ForgotPassword },
          {
            path: "forgot-my-password-reset",
            Component: ForgotPasswordReset,
          },
        ],
      },
      {
        path: "/",
        element: (
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            authenticationPath="/login"
            outlet={<AppLayout />}
          />
        ),
        errorElement: <RouterErrorBoundary />,
        children: [
          {
            index: true,
            element: (
              <SuspenseWithFallback>
                <Dashboard />
              </SuspenseWithFallback>
            ),
          },
          /* PATHS FOR SITES */
          {
            path: "site/:siteId/:siteNameAndLocation",
            Component: SitePageWrapper,
          },
          {
            path: "site/:siteId/:siteNameAndLocation/settings/:tab?",
            element: (
              <SuspenseWithFallback>
                <SiteConfigPageWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/alarms/:tab?",
            element: (
              <SuspenseWithFallback>
                <SiteAlarmsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/analysis/:tab?",
            element: (
              <SuspenseWithFallback>
                <SiteAnalysisPageWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/terminalMode/:device?",
            element: (
              <SuspenseWithFallback>
                <RemoteAccessWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/terminalModeFullScreen/:device?",
            element: (
              <SuspenseWithFallback>
                <FullScreenRemoteAccessWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "leo/:siteId/:leoName/terminalMode/:device?",
            element: (
              <SuspenseWithFallback>
                <RemoteAccessWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "leo/:siteId/:leoName/terminalModeFullScreen/:device?",
            element: (
              <SuspenseWithFallback>
                <FullScreenRemoteAccessWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/floorplans",
            element: (
              <SuspenseWithFallback>
                <FloorplansWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/customGraph/:startDate?/:endDate?",
            element: (
              <SuspenseWithFallback>
                <CustomGraphsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/controller/:controllerName",
            element: (
              <SuspenseWithFallback>
                <ControllersViewWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/controller/:controllerName/graph/:applicationName/:startDate?/:endDate?",
            element: (
              <SuspenseWithFallback>
                <ApplicationsGraphWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "site/:siteId/:siteNameAndLocation/leoAlertRecords/:tab?/:startDate?/:endDate?",
            element: (
              <SuspenseWithFallback>
                <LeoAlertLogsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "leoAlertRecords/:tab?/:startDate?/:endDate?",
            element: (
              <SuspenseWithFallback>
                <LeoAlertLogsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "allSitesAlarms/:tab?",
            element: (
              <SuspenseWithFallback>
                <AllSiteAlarmsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "sitesWithActiveAlarms",
            element: (
              <SuspenseWithFallback>
                <SitesWithActiveAlarmsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "offlineSites/:tab?",
            element: (
              <SuspenseWithFallback>
                <OfflineSitesWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "siteList",
            element: (
              <SuspenseWithFallback>
                <SiteListWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "adminPortal/:tab?",
            element: (
              <SuspenseWithFallback>
                <AdminPortalWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "analytics/:date?/:report?",
            element: (
              <SuspenseWithFallback>
                <AnalyticsWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "help", // Not currently used
            Component: Help,
          },
          {
            path: "userAccount/:tab?",
            element: (
              <SuspenseWithFallback>
                <UserAccountWrapper />
              </SuspenseWithFallback>
            ),
          },
          {
            path: "*",
            Component: DoesNotExist,
          },
        ],
      },
    ];
  }, [isAuthenticated]);

  const router = useMemo(() => {
    if (import.meta.env.VITEST_POOL_ID !== undefined) {
      return createMemoryRouter(routes);
    }
    return createBrowserRouter(routes);
  }, [routes]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-US">
        <SnackbarProvider
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          Components={{
            success: SuccessSnackbar,
            error: ErrorSnackbar,
            warning: WarningSnackbar,
            info: InfoSnackbar,
          }}
          style={{ maxWidth: 400 }}
        >
          <CssBaseline />

          {!isAuthenticated && (
            <ToggleColorPreference
              sx={{
                position: "absolute",
                right: "1%",
                top: "6px",
                bgcolor: "background.default",
                color: "text.primary",
                zIndex: 2,
              }}
            />
          )}

          {isLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
              }}
              data-testid="permissionsLoading"
            >
              <Loading />
            </Box>
          ) : (
            <RouterProvider router={router} fallbackElement={<Loading />} />
          )}

          {/* We need this dialog placed here to show it while the login component is showing */}
          <SessionExpiredDialog />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
