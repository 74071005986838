import { useState, useEffect, useMemo, useCallback } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { inputBaseClasses } from "@mui/material/InputBase";
import { MuiOtpInput } from "mui-one-time-password-input";
import Loading from "../../../components/Utility/Loading";
import { useTwoFactorMutation } from "../../../features/auth/authAPI";
import {
  selectUsername,
  selectAuthToken,
  selectIsAuthenticated,
} from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import { enqueueSnackbar } from "notistack";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useCountdown } from "usehooks-ts";
import { Duration } from "luxon";
import Link from "@mui/material/Link";
import Fade from "@mui/material/Fade";
import useCheckIfMatchThenNavigate from "../../../hooks/appHooks/useCheckIfMatchThenNavigate";
import {
  isFetchBaseQueryError,
  isSerializedError,
} from "../../../utils/typeChecks";

export type RedirectLocationState = {
  redirectTo: Location;
};

const TwoFactorCode = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const token = useAppSelector(selectAuthToken);
  const username = useAppSelector(selectUsername);

  const initCountStart = useCallback(() => {
    const sessionVal = sessionStorage.getItem("2FacCount");
    if (sessionVal && !Number.isNaN(parseInt(sessionVal))) {
      return parseInt(sessionVal);
    }
    return 600;
  }, []);
  const countStart = useMemo(() => initCountStart(), [initCountStart]);

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: countStart,
      intervalMs: 1000,
    });

  const navigateAway = useCheckIfMatchThenNavigate(
    token === "" || username === "" || count === 0,
    "/login",
  );

  const prettyCount = useMemo(() => {
    return Duration.fromObject({ seconds: count }).toFormat("mm:ss");
  }, [count]);

  useEffect(() => {
    console.info("startedCountDown");
    startCountdown();
    return () => {
      stopCountdown();
      resetCountdown();
    };
  }, [startCountdown, stopCountdown, resetCountdown]);
  useEffect(() => {
    if (count > 1) {
      sessionStorage.setItem("2FacCount", `${count}`);
    } else {
      sessionStorage.removeItem("2FacCount");
    }
  }, [count]);

  useEffect(() => {
    // if the user isAuthenticated we want to force them off this page
    if (isAuthenticated) {
      sessionStorage.removeItem("2FacCount");
      if (
        locationState &&
        "redirectTo" in locationState &&
        locationState.redirectTo !== undefined
      ) {
        const { redirectTo } = locationState as RedirectLocationState;
        navigate(`${redirectTo.pathname}${redirectTo.search}`);
      } else {
        navigate("/");
      }
    }
  }, [isAuthenticated, navigate, locationState]);

  const [twoFactorCode, setTwoFactorCode] = useState(
    searchParams.get("twoFactorCode") ?? "",
  );
  useEffect(() => {
    if (
      searchParams.get("twoFactorCode") !== null ||
      searchParams.get("username") !== null
    ) {
      setSearchParams("");
    }
  }, [searchParams, setSearchParams]);

  const handleTwoFactorCodeChange = (newValue: string) => {
    setTwoFactorCode(newValue);
  };
  const [checkTwoFactor, { isLoading }] = useTwoFactorMutation();
  const handleComplete = async (finalValue: string) => {
    try {
      await checkTwoFactor({
        username,
        two_factor_code: parseInt(finalValue),
      }).unwrap();
    } catch (err) {
      if (isSerializedError(err)) {
        enqueueSnackbar(
          "Something went wrong. Please try again or check your code.",
          {
            variant: "error",
          },
        );
      } else if (
        isFetchBaseQueryError(err) &&
        (err.data as { error: string | undefined })?.error ===
          "Two Factor Code is invalid"
      ) {
        enqueueSnackbar(
          "The code you entered is invalid. Please check it and try again.",
          {
            variant: "error",
          },
        );
      } else if (
        isFetchBaseQueryError(err) &&
        (err.data as { error: string | undefined })?.error ===
          "Two Factor Authentication code is expired"
      ) {
        enqueueSnackbar(
          "The code you entered has expired. Please login again.",
          {
            variant: "error",
          },
        );
        sessionStorage.removeItem("2FacCount");
        navigate("/login");
      } else {
        enqueueSnackbar(
          "Something went wrong. Please try again or check your code.",
          {
            variant: "error",
          },
        );
      }
    }
  };

  if (navigateAway) {
    sessionStorage.removeItem("2FacCount");
    return navigateAway;
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 8,
        mb: 1,
        position: "relative",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography data-testid="loginHeader" component="h1" variant="h5">
          Two Factor Auth Code
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Enter the 6-digit code sent to your email
        </Typography>
        <Box sx={{ mt: 1, position: "relative" }}>
          <MuiOtpInput
            value={twoFactorCode}
            onChange={handleTwoFactorCodeChange}
            onComplete={handleComplete}
            length={6}
            autoFocus
            TextFieldsProps={{
              type: "tel",
              inputMode: "numeric",
              color: "secondary",
              sx: {
                [`& .${inputBaseClasses.root}`]: {
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.1)"
                      : "rgba(0,0,255,0.1)",
                  transition: (theme) =>
                    theme.transitions.create([
                      "background-color",
                      "border-color",
                    ]),
                  [`&.${inputBaseClasses.focused}`]: {
                    backgroundColor: "transparent",
                  },
                },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 0.2,
          }}
        >
          <Typography color="text.secondary">
            {prettyCount} Remaining
          </Typography>
          <Fade in={count <= 240}>
            <Link
              component={RouterLink}
              to="/login"
              color="secondary"
              aria-label="Navigate to the Login Page"
            >
              Retry?
            </Link>
          </Fade>
        </Box>
        {isLoading && (
          <Box
            sx={{
              background: (theme) => `${theme.palette.background.default}AA`,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              display: "flex",
            }}
          >
            <Loading />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default TwoFactorCode;
