import { useEffect, useRef } from "react";
import { GetSitesResponse, SiteType } from "../../features/sites/sitesAPI";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../app/hooks";
import {
  IExpiredStoresList,
  showExpiredSitesDialog,
} from "../../features/view/viewSlice";

const EXPIRED_COOKIE_NAME = "expiredStoreNums";
const SOON_TO_EXPIRE_COOKIE_NAME = "soonToExpireSites";

const useCheckExpiredSites = (sites: GetSitesResponse | undefined) => {
  const dispatch = useAppDispatch();
  const cookies = document.cookie.split(";"); // Does this actually stay up to date???

  const hasChecked = useRef(false);
  useEffect(() => {
    if (sites && !hasChecked.current) {
      hasChecked.current = true;
      const today = DateTime.now()
        .setZone("utc")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const expiredSites: SiteType[] = [];
      const soonToExpireSites: SiteType[] = [];

      for (const site of sites) {
        if (site.cloud_end_date && site.leo_installed) {
          const expirationDate = DateTime.fromISO(site.cloud_end_date).setZone(
            "utc",
          );
          const monthBackFromExpiration = expirationDate.minus({ days: 35 });

          if (today >= expirationDate) {
            expiredSites.push(site);
          } else if (
            today >= monthBackFromExpiration &&
            today <= expirationDate
          ) {
            soonToExpireSites.push(site);
          }
        }
      }

      let expiredCookieExists = false;
      const unWarnedExpiredStores: SiteType[] = [];
      let soonToExpireCookieExists = false;
      const unWarnedSoonToExpireSites: SiteType[] = [];

      for (const cookie of cookies) {
        const [cookieName, cookieVal] = cookie.trim().split("=");
        if (cookieName === EXPIRED_COOKIE_NAME) {
          expiredCookieExists = true;
          const siteNums = cookieVal.split("~");
          for (const site of expiredSites) {
            const existsInCookies = siteNums.find(
              (num) => num === `${site.store_num}`,
            );
            if (!existsInCookies) {
              unWarnedExpiredStores.push(site);
            }
          }
        }
        if (cookieName === SOON_TO_EXPIRE_COOKIE_NAME) {
          soonToExpireCookieExists = true;
          const siteNums = cookieVal.split("~");
          for (const site of soonToExpireSites) {
            const existsInCookies = siteNums.find(
              (num) => num === `${site.store_num}`,
            );
            if (!existsInCookies) {
              unWarnedSoonToExpireSites.push(site);
            }
          }
        }
      }

      const expiredSitesDialogData: {
        expiredSites: IExpiredStoresList;
        soonToExpireSites: IExpiredStoresList;
      } = {
        expiredSites: [],
        soonToExpireSites: [],
      };

      if (expiredCookieExists) {
        if (unWarnedExpiredStores.length > 0) {
          const siteList: IExpiredStoresList = unWarnedExpiredStores.map(
            (s) => ({
              storeName: s.store_name,
              storeNum: s.store_num,
              expirationDate: DateTime.fromISO(s.cloud_end_date as string)
                .setZone("utc")
                .toLocaleString(),
            }),
          );
          // set cookies
          document.cookie = `${EXPIRED_COOKIE_NAME}=${expiredSites
            .map((v) => v.store_num)
            .join("~")}; max-age=86400; path=/; samesite=strict;`;

          expiredSitesDialogData.expiredSites = siteList;
        }
      } else {
        const siteList: IExpiredStoresList = expiredSites.map((s) => ({
          storeName: s.store_name,
          storeNum: s.store_num,
          expirationDate: DateTime.fromISO(s.cloud_end_date as string)
            .setZone("utc")
            .toLocaleString(),
        }));
        if (siteList.length > 0) {
          // set cookies
          document.cookie = `${EXPIRED_COOKIE_NAME}=${expiredSites
            .map((v) => v.store_num)
            .join("~")}; max-age=86400; path=/; samesite=strict;`;

          // show warning dialog
          expiredSitesDialogData.expiredSites = siteList;
        }
      }

      if (soonToExpireCookieExists) {
        if (unWarnedSoonToExpireSites.length > 0) {
          const siteList: IExpiredStoresList = unWarnedSoonToExpireSites.map(
            (s) => ({
              storeName: s.store_name,
              storeNum: s.store_num,
              expirationDate: DateTime.fromISO(s.cloud_end_date as string)
                .setZone("utc")
                .toLocaleString(),
            }),
          );
          // set cookies
          document.cookie = `${SOON_TO_EXPIRE_COOKIE_NAME}=${soonToExpireSites
            .map((v) => v.store_num)
            .join("~")}; max-age=86400; path=/; samesite=strict;`;

          expiredSitesDialogData.soonToExpireSites = siteList;
        }
      } else {
        const siteList: IExpiredStoresList = soonToExpireSites.map((s) => ({
          storeName: s.store_name,
          storeNum: s.store_num,
          expirationDate: DateTime.fromISO(s.cloud_end_date as string)
            .setZone("utc")
            .toLocaleString(),
        }));
        if (siteList.length > 0) {
          // set cookies
          document.cookie = `${SOON_TO_EXPIRE_COOKIE_NAME}=${soonToExpireSites
            .map((v) => v.store_num)
            .join("~")}; max-age=86400; path=/; samesite=strict;`;

          // show warning dialog
          expiredSitesDialogData.soonToExpireSites = siteList;
        }
      }

      if (
        expiredSitesDialogData.expiredSites.length > 0 ||
        expiredSitesDialogData.soonToExpireSites.length > 0
      ) {
        console.log({ expiredSitesDialogData });
        dispatch(showExpiredSitesDialog(expiredSitesDialogData));
      }
    }
  }, [sites, cookies, dispatch]);
};

export default useCheckExpiredSites;
