import { useImperativeHandle, forwardRef, useRef, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectShowExpiredSitesDialog,
  closeExpiredSitesDialog,
  selectExpiredSitesList,
  selectSoonToExpireSites,
} from "../../features/view/viewSlice";
import { Divider } from "@mui/material";

type Ref = {
  focus: () => void;
} | null;
interface Props {
  handleClose: () => void;
}

const ExpiredSitesContent = forwardRef<Ref, Props>(({ handleClose }, ref) => {
  const expiredSites = useAppSelector(selectExpiredSitesList);
  const soonToExpireSites = useAppSelector(selectSoonToExpireSites);

  const title = useMemo(() => {
    if (expiredSites.length > 0 && soonToExpireSites.length > 0) {
      return "Expired and soon-to-expire subscriptions";
    }
    if (expiredSites.length > 0) {
      return "Subscriptions that have expired";
    }
    if (soonToExpireSites.length > 0) {
      return "Subscriptions nearing expiration";
    }
  }, [expiredSites.length, soonToExpireSites.length]);

  const subtitle = useMemo(() => {
    if (expiredSites.length > 0 && soonToExpireSites.length > 0) {
      return "The following subscriptions have expired and some are expiring soon.";
    }
    if (expiredSites.length > 0) {
      return "The following subscriptions have expired ";
    }
    if (soonToExpireSites.length > 0) {
      return "The following subscriptions will expire soon";
    }
  }, [expiredSites.length, soonToExpireSites.length]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({
    focus() {
      buttonRef.current?.focus({ preventScroll: true });
    },
  }));

  return (
    <>
      <DialogTitle
        sx={{
          color: (theme) =>
            theme.palette.warning[
              theme.palette.mode === "dark" ? "light" : "dark"
            ],
        }}
      >
        {title}
        <Typography variant="subtitle2" color="textSecondary" component="p">
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container flex="1">
          {expiredSites.length > 0 && (
            <>
              <Grid size={12} marginBottom={1}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    color: (theme) =>
                      theme.palette.error[
                        theme.palette.mode === "dark" ? "light" : "dark"
                      ],
                  }}
                >
                  Expired Subscriptions
                </Typography>
              </Grid>
              {expiredSites.map((sto, i) => {
                return (
                  <Grid
                    key={sto.storeNum}
                    size={12}
                    display="flex"
                    flexDirection="row"
                  >
                    <Typography>
                      {i + 1}. <strong>{sto.storeName}</strong> has expired on{" "}
                      {sto.expirationDate}
                    </Typography>
                  </Grid>
                );
              })}
            </>
          )}

          {expiredSites.length > 0 && soonToExpireSites.length > 0 && (
            <Grid size={12} marginTop={1} marginBottom={1}>
              <Divider />
            </Grid>
          )}

          {soonToExpireSites.length > 0 && (
            <>
              <Grid size={12} marginBottom={1}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    color: (theme) =>
                      theme.palette.info[
                        theme.palette.mode === "dark" ? "light" : "dark"
                      ],
                  }}
                >
                  Expiring Soon
                </Typography>
              </Grid>
              {soonToExpireSites.map((sto, i) => {
                return (
                  <Grid
                    key={sto.storeNum}
                    size={12}
                    display="flex"
                    flexDirection="row"
                  >
                    <Typography>
                      {i + 1}. <strong>{sto.storeName}</strong> will expire on{" "}
                      {sto.expirationDate}
                    </Typography>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogContentText marginTop={1} textAlign="center">
        Please contact Hunter Liberty Customer Service at{" "}
        <Link
          href="tel:+1(678)608-0204"
          sx={{
            color: (theme) =>
              theme.palette.info[
                theme.palette.mode === "dark" ? "light" : "dark"
              ],
          }}
        >
          (678) 608-0204
        </Link>{" "}
        to renew your subscription.
      </DialogContentText>
      <DialogActions>
        <Button
          sx={{
            ":focus": {
              boxShadow: "0px 0px 19px 1px rgba(232,255,61,1)",
            },
          }}
          ref={buttonRef}
          variant="contained"
          onClick={handleClose}
        >
          OKAY
        </Button>
      </DialogActions>
    </>
  );
});

const ExpiredSitesDialog = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(selectShowExpiredSitesDialog);

  const buttonHandle = useRef<Ref>(null);

  const handleClose = () => {
    dispatch(closeExpiredSitesDialog());
  };

  const onClose = () => {
    // highlight acknowledge Button by focus
    buttonHandle.current?.focus();
  };

  return (
    <Dialog open={show} onClose={onClose} scroll="paper">
      <ExpiredSitesContent ref={buttonHandle} handleClose={handleClose} />
    </Dialog>
  );
};

export default ExpiredSitesDialog;
