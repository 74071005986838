import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { store } from "../../app/store";
import {
  usersApi,
  RawUserPreferences,
  DisableDashboardMapPreference,
  SaveCustomThemePreference,
} from "../users/usersAPI";
import { getCookie } from "../../utils/cookieHandlers";

export interface IExpiredStore {
  storeName: string;
  storeNum: number;
  expirationDate: string;
}
export type IExpiredStoresList = IExpiredStore[];

export type OldCookiesColorPallete = {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  error: {
    main: string;
  };
  warning: {
    main: string;
  };
  info: {
    main: string;
  };
  success: {
    main: string;
  };
};

function isOldCookiesColorPalette(obj: unknown): obj is OldCookiesColorPallete {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj === "object" &&
    "primary" in obj &&
    !("background" in obj)
  );
}

export type EditablePallete = {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  error: {
    main: string;
  };
  warning: {
    main: string;
  };
  info: {
    main: string;
  };
  success: {
    main: string;
  };
  background: {
    default: {
      light: string;
      dark: string;
    };
    paper: {
      light: string;
      dark: string;
    };
  };
};

function isNewCookiesColorPalette(obj: unknown): obj is EditablePallete {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj === "object" &&
    "primary" in obj &&
    "background" in obj
  );
}

export type CookiesColorPallete = OldCookiesColorPallete | EditablePallete;
export interface ViewState {
  mode: PaletteMode;
  palette: EditablePallete;
  showDrawer: boolean;
  showSites: boolean;
  showSessionExpiredDialog: boolean;
  showExpiredSitesDialog: boolean;
  expiredSites: IExpiredStoresList;
  soonToExpireSites: IExpiredStoresList;
  showUnauthorizedSiteAccess: boolean;
  siteToFocus: number | null;
  disableDashboardMap: boolean;
  isSmallDevice: boolean;
  isMediumDevice: boolean;
  isDevToolsOpen: boolean;
  isTestingEnv: boolean;
}

// this looks pretty good. maybe darken the primary and secondary slightly
// {
//   "colorPalette": {
//     "primary": {
//       "main": "#8ff0a4"
//     },
//     "secondary": {
//       "main": "#99c1f1"
//     },
//     "error": {
//       "main": "#f66151"
//     },
//     "success": {
//       "main": "#26a269"
//     },
//     "warning": {
//       "main": "#ffbe6f"
//     },
//     "info": {
//       "main": "#f9f06b"
//     },
//     "background": {
//       "default": {
//         "light": "#fafafa",
//         "dark": "#303030"
//       },
//       "paper": {
//         "light": "#ffffff",
//         "dark": "#424242"
//       }
//     }
//   }
// }
export const PALETTE_DEFAULT: EditablePallete = {
  // CURRENT THEME
  primary: {
    // orignal in comments
    // HLGREEN = #2a652a
    main: "#548354", // '#158eec',
  },
  secondary: {
    main: "#158eec", // '#ec7215',
  },
  error: {
    main: "#f44336",
  },
  success: {
    main: "#2e7d32", // #40bf6d <- This is a great looking green text color but not so good on the sidebar
  },
  warning: {
    main: "#ed6c02",
  },
  info: {
    main: "#f5f3e5", // '#0288d1',
  },
  background: {
    default: {
      light: "#fafafa",
      dark: "#303030",
    },
    paper: {
      light: "#ffffff",
      dark: "#424242",
    },
  },
};

const COOKIE_NAME = "ColorPalette";

// need to Modify Old palettes to include the background
const getColorPaletteFromCookies = () => {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const [cookieName, cookieVal] = cookie.trim().split("=");

    if (cookieName === COOKIE_NAME) {
      // need to modify old palettes to include background
      const colorPalette = JSON.parse(cookieVal) as CookiesColorPallete;
      console.info({ colorPalette });
      if (isOldCookiesColorPalette(colorPalette)) {
        const updatedPalette: EditablePallete = {
          ...colorPalette,
          background: PALETTE_DEFAULT.background,
        };
        console.info("modified old colorPalette");
        return updatedPalette;
      } else if (isNewCookiesColorPalette(colorPalette)) {
        console.info("no modification to palettte needed");
        return colorPalette;
      }
    }
  }

  return null;
};
// const saveColorPaletteToCookies = (colorPalette: EditablePallete) => {
//   document.cookie = `${COOKIE_NAME}=${JSON.stringify(
//     colorPalette,
//   )}; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/; samesite=strict;`;
// };

const getDisableDashboardMapCookie = () => {
  const COOKIE_NAME = "disableDashboardMap";

  const cookieValueUnparsed = getCookie(COOKIE_NAME);

  if (cookieValueUnparsed) {
    return cookieValueUnparsed === "true";
  }

  return null;
};
// const updateDisableDashboardMap = (value: boolean) => {
//   const COOKIE_NAME = "disableDashboardMap";
//   setCookie(COOKIE_NAME, value.toString());
// };

const checkIfTestingEnv = () => {
  if (import.meta.env.VITEST_POOL_ID !== undefined) {
    return true;
  }
  return false;
};

const initialState: ViewState = {
  mode: "light",
  showDrawer: true,
  showSites: false,
  showSessionExpiredDialog: false,
  showExpiredSitesDialog: false,
  showUnauthorizedSiteAccess: false,
  expiredSites: [],
  soonToExpireSites: [],
  siteToFocus: null,
  isSmallDevice: false,
  isMediumDevice: false,
  // TODO Create method to check for preferences in the cookies and apply them to the DB if they don't exist
  palette: PALETTE_DEFAULT,
  disableDashboardMap: false,
  isDevToolsOpen: false,
  isTestingEnv: checkIfTestingEnv(),
};

export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    toggleMode: (state) => {
      if (state.mode === "dark") state.mode = "light";
      else state.mode = "dark";
    },
    toggleDrawer: (state) => {
      if (state.showDrawer) {
        state.showDrawer = false;
      } else {
        state.showDrawer = true;
      }
    },
    toggleSitesDrawer: (state) => {
      // need to make sure this gets toggled off when a user logs out
      if (state.showSites) {
        state.showSites = false;
      } else {
        state.showSites = true;
      }
    },
    showSessionExpiredDialog: (state, action: PayloadAction<boolean>) => {
      state.showSessionExpiredDialog = action.payload;
    },
    closeSessionExpiredDialog: (state) => {
      state.showSessionExpiredDialog = false;
    },
    closeExpiredSitesDialog: (state) => {
      state.showExpiredSitesDialog = false;
    },
    showExpiredSitesDialog: (
      state,
      action: PayloadAction<{
        expiredSites: IExpiredStoresList;
        soonToExpireSites: IExpiredStoresList;
      }>,
    ) => {
      state.expiredSites = action.payload.expiredSites;
      state.soonToExpireSites = action.payload.soonToExpireSites;
      state.showExpiredSitesDialog = true;
    },
    forceCloseDrawerAndSites: (state) => {
      state.showSites = false;
      state.showDrawer = false;
    },
    focusSite: (state, action: PayloadAction<number>) => {
      state.showDrawer = true;
      state.showSites = true;
      state.siteToFocus = action.payload;
    },
    clearOutFocusSiteState: (state) => {
      state.siteToFocus = null;
    },
    setIsSmallDevice: (state, action: PayloadAction<boolean>) => {
      state.isSmallDevice = action.payload;
    },
    setIsMediumDevice: (state, action: PayloadAction<boolean>) => {
      state.isMediumDevice = action.payload;
    },
    // setThemePalette: (state, action: PayloadAction<EditablePallete>) => {
    //   saveColorPaletteToCookies(action.payload);
    //   state.palette = action.payload;
    // },
    // setDisableDashboardMap: (state, action: PayloadAction<boolean>) => {
    //   updateDisableDashboardMap(action.payload);
    //   state.disableDashboardMap = action.payload;
    // },
    handleShowUnauthorizedSiteAccess: (state) => {
      state.showUnauthorizedSiteAccess = true;
    },
    handleHideUnauthorizedSiteAccess: (state) => {
      state.showUnauthorizedSiteAccess = false;
    },
    setDevToolsOpen: (state) => {
      // code is setup in App.tsx for this to be used
      state.isDevToolsOpen = true;
    },
    setDevToolsClosed: (state) => {
      state.isDevToolsOpen = false;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      // sets up user preferences after successful request
      usersApi.endpoints.getUserPreferences.matchFulfilled,
      (state, { payload }) => {
        if (payload?.length) {
          for (const preference of payload) {
            switch (preference.preference_name) {
              case "customTheme":
                state.palette = preference.preference_value;
                break;
              case "disableDashboardMap":
                state.disableDashboardMap = preference.preference_value;
                break;
            }
          }
        } else {
          // determine if they had preferences already saved in their cookie
          const disableMapCookie = getDisableDashboardMapCookie();
          const colorPaletteCookie = getColorPaletteFromCookies();

          const newPreferences: RawUserPreferences[] = [];
          if (disableMapCookie !== null) {
            newPreferences.push({
              preference_category: "layout",
              preference_name: "disableDashboardMap",
              preference_value: disableMapCookie,
            } as DisableDashboardMapPreference);
          }
          if (colorPaletteCookie !== null) {
            newPreferences.push({
              preference_category: "appearance",
              preference_name: "customTheme",
              preference_value: JSON.stringify(colorPaletteCookie),
            } as SaveCustomThemePreference);
          }

          if (newPreferences?.length) {
            const userId = store.getState().auth.userId;
            if (userId > 0) {
              store.dispatch(
                usersApi.endpoints.setUserPreferences.initiate({
                  preferences: newPreferences,
                  user_id: userId,
                }),
              );
            }
          }
        }
      },
    );
  },
});

export const {
  toggleMode,
  toggleDrawer,
  toggleSitesDrawer,
  showSessionExpiredDialog,
  closeSessionExpiredDialog,
  closeExpiredSitesDialog,
  showExpiredSitesDialog,
  forceCloseDrawerAndSites,
  focusSite,
  clearOutFocusSiteState,
  setIsSmallDevice,
  setIsMediumDevice,
  handleShowUnauthorizedSiteAccess,
  handleHideUnauthorizedSiteAccess,
  setDevToolsOpen,
  setDevToolsClosed,
} = viewSlice.actions;

export const selectTheme = (state: RootState) => state.view.mode;
export const selectCustomThemePalette = (state: RootState) =>
  state.view.palette;

export const selectShowSessionExpiredDialog = (state: RootState) =>
  state.view.showSessionExpiredDialog;
export const selectShowExpiredSitesDialog = (state: RootState) =>
  state.view.showExpiredSitesDialog;

export const selectShowUnauthorizedSiteAccess = (state: RootState) =>
  state.view.showUnauthorizedSiteAccess;
export const selectExpiredSitesList = (state: RootState) =>
  state.view.expiredSites;
export const selectSoonToExpireSites = (state: RootState) =>
  state.view.soonToExpireSites;
export const selectShowDrawer = (state: RootState) => state.view.showDrawer;
export const selectShowSitesDrawer = (state: RootState) => state.view.showSites;
export const selectSiteToFocus = (state: RootState) => state.view.siteToFocus;
export const selectDisableDashboardMap = (state: RootState) =>
  state.view.disableDashboardMap;

export const selectIsTesting = (state: RootState) => state.view.isTestingEnv;

export const selectIsDevToolsOpen = (state: RootState) =>
  state.view.isDevToolsOpen; // CURRENTLY NOT USED (LOGIC IS MOVED INTO THE RemoteAccess.tsx) commented out codes in App.tsx

export const selectDeviceSize = (state: RootState) => ({
  isSmallDevice: state.view.isSmallDevice,
  isMediumDevice: state.view.isMediumDevice,
});

export default viewSlice.reducer;
